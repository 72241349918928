import { FC } from 'react';
import WaypointList from "./WaypointList";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
  Button,
  Avatar,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';


interface AccordionItemProps {
  location: {
    id: number;
    ShortName: string;
    LongName: string;
    ZoneId: string;
    Description: string;
    Color: string;
    ParkId: number;
    IsActive: boolean;
    WayPoints: any;
  };
  onConfirmDialogOpen: (zoneId: string, parkId: number, wpId?: any) => void;
  expanded: boolean;
  onChange: (event: React.SyntheticEvent, isExpanded: boolean) => void;
  classes: Record<string, string>;
  onZoneStatusToggled: (e: any, zone: any) => void;
  onZoneClick: (zoneId: string) => void;
  updating: boolean;
  loadingId: string;
}
const AccordionItem: FC<AccordionItemProps> = (props) => {
  const { location, onConfirmDialogOpen, expanded, onChange, classes, onZoneStatusToggled, onZoneClick, updating, loadingId } = props;
  return (
    <div>
      <Accordion
        key={location.ZoneId}
        id={location.ZoneId}
        expanded={expanded}
        onChange={onChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="zone-content"
          id="zone-header"
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} columns={24}>
              <Grid size={3}>
                <Avatar style={{ backgroundColor: location.Color }}>
                  &nbsp;
                </Avatar>
              </Grid>
              <Grid size={8}>
                <Typography className={classes.heading} >
                  {location.ShortName}
                </Typography>
              </Grid>
              <Grid size={7}>
                <Typography className={classes.secondaryHeading} >
                  {location.Description}
                </Typography>
              </Grid>
              <Grid size={6}>
                {updating && location.ZoneId === loadingId ? (
                  <CircularProgress />
                ) : (
                  <FormControlLabel
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={
                      <Switch
                        color="primary"
                        checked={location.IsActive}
                        value={location.IsActive}
                        onChange={(e) => onZoneStatusToggled(e, location)}
                      />
                    }
                    label={
                      <Typography className={classes.secondaryHeading} >
                        Active
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </AccordionSummary>
        <AccordionActions
          style={{ justifyContent: "flex-start", marginLeft: "10px" }}
        >
          <Button
            size="small"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => onZoneClick(location.ZoneId)}
          >
            Edit Zone
          </Button>
          <Button
            size="small"
            startIcon={<DeleteIcon />}
            onClick={() =>
              onConfirmDialogOpen(location.ZoneId, location.ParkId)
            }
          >
            Delete Zone
          </Button>
        </AccordionActions>
        <AccordionDetails>
          <Box sx={{
            width: { xs: 500, sm: 500, md: 500, lg: 500, xl: 630 },
            overflowX: "scroll"
          }}>

            {<WaypointList
              zone={location}
              points={location.WayPoints}
              confirmDeletePoint={onConfirmDialogOpen}
            />}
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default AccordionItem;