import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'hookrouter';
//import contrast from 'font-color-contrast';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import AuthContext from 'context/Auth/auth';
import { updatePointStatus, updatepointsequence } from 'services/Waypoint.Service';

import { makeStyles } from '@mui/styles';
import {
  Typography,
  Button,
  ButtonGroup,
  Avatar,
  FormControlLabel,
  Switch,
  CircularProgress,
} from '@mui/material';

import { useDemoData } from '@mui/x-data-grid-generator';
import {
  DataGridPro
} from '@mui/x-data-grid-pro';

function updateRowPosition(initialIndex, newIndex, rows) {
  return new Promise((resolve) => {
    setTimeout(
      () => {
        const rowsClone = [...rows];
        const row = rowsClone.splice(initialIndex, 1)[0];
        rowsClone.splice(newIndex, 0, row);
        resolve(rowsClone);
      },
      Math.random() * 500 + 100,
    ); // simulate network latency
  });
}

const useStyles = makeStyles(theme => ({
  table: {},
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));
//#endregion

function WaypointList({ zone, points, confirmDeletePoint}) {
  const authContext = useContext(AuthContext);
  const classes = useStyles();
  const [waypoints, setWaypoints] = useState(points);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [loadingId, setLoadingId] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState({ severity: null, message: '' });

  const handleStatusToggle = (e, point) => {
    setUpdatingStatus(true);
    setLoadingId(point.Id);

    const waypointsCopy = waypoints;
    const updatedPoint = waypointsCopy.find(p => p.Id === point.Id);
    
    if (point.IsActive === undefined) {
      point.IsActive = false;
    }

    point.IsActive = !point.IsActive;
    updatedPoint.IsActive = point.IsActive;
    updatePointStatus(
      authContext,
      authContext.tbProps.selectedPark.ParkId,
      zone.ZoneId,
      point.Id,
      point.IsActive
    ).then(_msg => {
      //const indexForPoint = waypointsCopy.indexOf(updatedPoint);
      //waypointsCopy.splice(indexForPoint, 1, updatedPoint);

      setWaypoints(waypointsCopy);
      setUpdatingStatus(false);      
    });
  };

  const { loading: initialLoadingState } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 20,
    maxColumns: 20,
  });

  const [rows, setRows] = React.useState(waypoints);
  const [loading, setLoading] = React.useState(initialLoadingState);

  React.useEffect(() => {
    setRows(waypoints);
  }, [waypoints]);

  React.useEffect(() => {
    setRows(points);
  }, [points]);

  React.useEffect(() => {
    setLoading(initialLoadingState);
  }, [initialLoadingState]);

  const handleRowOrderChange = async (params) => {
    setLoading(true);
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows,
    );
    updatepointsequence(
      authContext,
      newRows,
      zone.ZoneId,
    ).then(_msg => {
      setRows(newRows);
      setLoading(false);
    }).catch(_msg => {
      setRows(newRows);
      setLoading(false);
      setOpen(true);
      setAlert({ severity: 'error', message: _msg.message });
    }).finally(_msg => {
      setRows(newRows);
      setLoading(false);
    });
  };

  const columns = [
    { field: 'ShortName', headerName: 'Name', minWidth: 80, flex: 1,editable: true },
    { field: 'Description', headerName: 'Description', minWidth: 80, flex: 1,editable: true },
    {
      field: 'Color', headerName: 'Color', minWidth: 50, flex: 1,type: 'actions', editable: true,
      getActions: (point) => [
        <Avatar style={{ backgroundColor: point.row.Color }}>&nbsp;</Avatar>
      ],
    },
    {
      field: 'IsActive', headerName: 'Currently Active', minWidth: 120, flex: 1,type: 'actions', editable: true,
      getActions: (point) => [
        updatingStatus && point.id === loadingId ? (
          <CircularProgress />
        ) : (
          <FormControlLabel
            onClick={event => event.stopPropagation()}
            onFocus={event => event.stopPropagation()}
            control={
              <Switch
                color='primary'
                checked={point.row.IsActive}
                value={point.row.IsActive}
                onChange={e => handleStatusToggle(e, point.row)}
              />
            }
            label={
              <Typography className={classes.secondaryHeading}>
                Active
              </Typography>
            }
            labelPlacement='start'
          />
        )
      ],
    },
    {
      field: 'actions',
      type: 'actions',
      minWidth: 200,
      flex: 1,
      getActions: (point) => [
        <ButtonGroup color='primary' aria-label='waypoint-edit-options'>
          <Button
            variant='contained'
            onClick={() =>
              navigate(
                `/explorer-quest/zone/${zone.ZoneId}/waypoint/${point.row.Id}/edit`
              )
            }
          >
            Edit
          </Button>
          <Button
            variant='contained'
            onClick={() =>
              confirmDeletePoint(zone.ZoneId, zone.ParkId, point.row.Id)
            }
            style={{
              backgroundColor: '#e74c3c',
              color: '#ecf0f1',
            }}
          >
            Delete
          </Button>
        </ButtonGroup>,
      ],
    },
  ];

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div style={{ width: "100%"}}>
      <DataGridPro
        columns={columns}
        loading={loading}
        rows={rows}
        rowReordering
        onRowOrderChange={handleRowOrderChange}
        getRowId={(row) => `point-${row.Id}`}
        autosizeOptions={{
          includeOutliers: true,
          includeHeaders: false,
        }}
        hideFooterPagination hideFooterSelectedRowCount hideFooter
      />


      <Button
        variant='contained'
        color='primary'
        disabled={false}
        style={{ margin: '10px' }}
        onClick={() =>
          navigate(`/explorer-quest/zone/${zone.ZoneId}/waypoint/new`)
        }
      >
        Create a New Point
      </Button>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

WaypointList.propTypes = {
  zone: PropTypes.object,
  points: PropTypes.array,
  confirmDeletePoint: PropTypes.func.isRequired,
};

export default WaypointList;
