import { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import { navigate } from "hookrouter";

import AuthContext from "context/Auth/auth";
import {
  getZones,
  deleteZone,
  updateZoneStatus,
  updateHuntSequence,
} from "services/ZonesService";
import { Zone } from "models/Zone";
import { getPoints, deletePoint } from "services/Waypoint.Service";
import { Point } from "models/Point";
import ConfirmationDialog from "helpers/ConfirmationDialog";
import AccordionItem from "./AccordionItem";

import { makeStyles } from "@mui/styles";
import { Button, CircularProgress, Backdrop } from "@mui/material";
import Box from "@mui/material/Box";

import { useDemoData } from "@mui/x-data-grid-generator";
import { DataGridPro } from "@mui/x-data-grid-pro";

function updateRowPosition(initialIndex, newIndex, rows) {
  return new Promise((resolve) => {
    const rowsClone = [...rows];
    const row = rowsClone.splice(initialIndex, 1)[0];
    rowsClone.splice(newIndex, 0, row);
    resolve(rowsClone);
  });
}

const NoLocationsText = styled.div`
  text-align: center;
  font-style: italic;
  font-size: 1.35em;
  margin-top: 65px;
`;

//#region Styles
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#FFF",
  },
  table: {},
  grid: {
    "& > *": {
      alignSelf: "center",
    },
  },
  heading: {
    paddingLeft: "10px",
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  accordionDetails: {
    width: "100%",
  },
}));
//#endregion

function ZoneList({ onZoneSelected }) {
  const authContext = useContext(AuthContext);
  const [createButtonDisabled, setCreateButtonDisabled] = useState(true);
  const [locationsInPark, setLocationsInPark] = useState([]);
  const [pointsInZone, setPointsInZone] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationForPoint, setShowConfirmationForPoint] =
    useState(false);
  const [zoneIdForDelete, setZoneIdForDelete] = useState(null);
  const [parkIdForDelete, setParkIdForDelete] = useState(null);
  const [pointIdForDelete, setPointIdForDelete] = useState(null);
  const { loading: initialLoadingState } = useDemoData({
    dataSet: "Commodity",
    rowLength: 20,
    maxColumns: 20,
  });
  const [loading, setLoading] = useState(initialLoadingState);
  const [expanded, setExpanded] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [loadingId, setLoadingId] = useState(null);

  const classes = useStyles();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setPointsInZone(panel.WayPoints);
    onZoneSelected(panel);
  };

  const handleConfirmDialogOpen = (zoneId, parkId, wpId = null) => {
    setZoneIdForDelete(zoneId);
    setParkIdForDelete(parkId);
    setPointIdForDelete(wpId);

    if (wpId !== null) {
      setShowConfirmationForPoint(true);
    } else {
      setShowConfirmation(true);
    }
  };

  const handleZoneStatusToggled = (e, zone) => {
    e.stopPropagation();
    setUpdatingStatus(true);
    setLoadingId(zone.ZoneId);

    updateZoneStatus(
      authContext,
      zone.ParkId,
      zone.ZoneId,
      e.target.checked
    ).then((_msg) => {
      let locationsCopy = locationsInPark;
      const updatedZone = locationsCopy.find((z) => z.ZoneId === zone.ZoneId);
      //const indexForZone = locationsCopy.indexOf(updatedZone);
      updatedZone.IsActive = !updatedZone.IsActive;
      //locationsCopy.splice(indexForZone, 1, updatedZone);
      setLocationsInPark(locationsCopy);
      setUpdatingStatus(false);
    });
  };

  const handleConfirmDialogClose = () => {
    setShowConfirmation(false);
    setShowConfirmationForPoint(false);
  };

  const handleZoneDelete = () => {
    deleteZone(authContext, parkIdForDelete, zoneIdForDelete).then((_data) => {
      setShowConfirmation(false);
      let copy = [...locationsInPark];
      let filtered = copy.filter((item) => item.ZoneId !== zoneIdForDelete);
      setLocationsInPark(filtered);
    });
  };

  const handlePointDelete = () => {
    const payload = {
      ZoneId: zoneIdForDelete,
      Id: pointIdForDelete,
    };

    const locationsCopy = [...locationsInPark];

    const zone = locationsInPark.find(
      (zone) => zone.ZoneId === zoneIdForDelete
    );
    const indexForZone = locationsInPark.indexOf(zone);
    setLoading(true);
    deletePoint(authContext, payload, parkIdForDelete).then((_data) => {
      setShowConfirmationForPoint(false);
      let copy = [...zone.WayPoints];
      let filtered = copy.filter((item) => item.Id !== pointIdForDelete);
      zone.WayPoints = filtered;
      setPointsInZone(zone.WayPoints);
      locationsCopy.splice(indexForZone, 1, zone);
      setLocationsInPark(locationsCopy);
    });
    setLoading(false);
  };

  const handleZoneClick = (zoneId) => {
    navigate(`/explorer-quest/zone/${zoneId}`);
  };

  const columns = [
    {
      field: "zoneId",
      type: "actions",
      minWidth: 200,
      flex: 1,
      getActions: (location) => [
        <AccordionItem
          key={location.row.id}
          location={location.row}
          onConfirmDialogOpen={handleConfirmDialogOpen}
          classes={classes}
          expanded={expanded === location.row}
          onChange={handleChange(location.row)}
          onZoneStatusToggled={handleZoneStatusToggled}
          onZoneClick={handleZoneClick}
          updating={updatingStatus}
          loadingId={loadingId}
        />,
      ],
    },
  ];

  useEffect(() => {
    const fetchZonesAndPoints = async () => {
      try {
        setShowConfirmation(false);
        setLoading(true);

        if (authContext.tbProps.selectedPark) {
          const selectedParkId = authContext.tbProps.selectedPark.ParkId;
          setCreateButtonDisabled(false);

          // Fetch zones
          const zonesData = await getZones(authContext, selectedParkId);

          // Map the zones into Zone objects
          const zones = zonesData.Hunts
            .map((z) => {
              return new Zone(
                z.HuntId,
                z.ShortName,
                z.LongName,
                z.Description,
                z.Latitude,
                z.Longitude,
                z.Radius,
                z.Color,
                zonesData.ParkId,
                z.IsActive,
                []
              );
            })
            .sort((a, b) => a.Sequence - b.Sequence);

          // Transform zones and wait for all getPoints calls to complete
          const transformedZones = await Promise.all(
            zones.map(async (zone) => {
              const pointsData = await getPoints(authContext, zone);

              if (typeof pointsData.Points !== "undefined") {
                const wayPoints = pointsData.Points.map((p) => {
                  return new Point(
                    p.PointId,
                    p.ZoneId,
                    p.ShortName,
                    p.LongName,
                    p.Description,
                    p.Latitude,
                    p.Longitude,
                    p.Radius,
                    p.Color,
                    p.Proximity,
                    p.ImageId,
                    p.Image,
                    p.IsHidden,
                    p.IsActive,
                    p.Activity
                  );
                });
                zone.WayPoints = wayPoints;
              }

              return {
                ...zone, // Spread original zone properties
                id: zone.ZoneId, // Map ZoneId to id for convenience
              };
            })
          );

          // Set locations and stop loading
          setLocationsInPark(transformedZones);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching zones or points:", error);
        setLoading(false); // Ensure loading stops even on error
      }
    };

    fetchZonesAndPoints();
  }, [authContext.tbProps.selectedPark]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(initialLoadingState);
  }, [initialLoadingState]);

  const handleRowOrderChange = async (params) => {
    const selectedParkId = authContext.tbProps.selectedPark.ParkId;
    setLoading(true);
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      locationsInPark
    );
    setLocationsInPark(newRows);
    updateHuntSequence(authContext, newRows, selectedParkId)
      .then((_msg) => {
        setLoading(false);
      })
      .catch((_msg) => {
        setLoading(false);
      })
      .finally((_msg) => {
        setLoading(false);
      });
    setLoading(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disabled={createButtonDisabled}
        onClick={() => navigate("/explorer-quest/zone/new")}
        style={{ marginBottom: "10px" }}
      >
        Create a New Zone
      </Button>

      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress />
        </Backdrop>
      ) : locationsInPark.length === 0 ? (
        <NoLocationsText>
          No locations created for Explorer Quest.
        </NoLocationsText>
      ) : (
        <Box sx={{ height: "100%", width: "100%" }}>
          <DataGridPro
            sx={{
              "& .MuiDataGrid-cell": {
                padding: 0,
              },
            }}
            slots={{
              columnHeaders: () => null,
            }}
            columns={columns}
            loading={loading}
            rows={locationsInPark}
            rowReordering
            onRowOrderChange={handleRowOrderChange}
            getRowHeight={() => "auto"}
            autosizeOptions={{
              columns: ["zoneId"],
              includeOutliers: true,
              includeHeaders: false,
            }}
            hideFooterPagination
            hideFooterSelectedRowCount
            hideFooter
          />
        </Box>
      )}

      <ConfirmationDialog
        open={showConfirmation}
        close={handleConfirmDialogClose}
        confirm={handleZoneDelete}
        warning={true}
      />

      <ConfirmationDialog
        open={showConfirmationForPoint}
        close={handleConfirmDialogClose}
        confirm={handlePointDelete}
        warning={false}
      />
    </>
  );
}

export default ZoneList;
