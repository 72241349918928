import React, { useState, useEffect, useContext } from 'react';
import { navigate } from 'hookrouter';

import AuthContext from 'context/Auth/auth';
import { UserContext } from 'context/UserContext';
import { hasAuthorization } from 'helpers/HasAuthorization';
import { useLocalStorage } from 'custom-hooks';
import Alert from 'components/common/Alert';
import TabPanel from 'components/common/TabPanel';
import A11yProps from 'components/common/A11yProps';

import { getZones } from 'services/ZonesService';
import { getParkBoundary } from 'services/Parks.Service';
import { Zone } from 'models/Zone';

import InfoBar from './cms/common/InfoBar';

import RewardsTierTab from './tabs/RewardsTierTab';
import QuestBuilderTab from './tabs/QuestBuilderTab';
import SetupTab from './tabs/SetupTab';

import { makeStyles } from '@mui/styles'
import {
  Typography,
  Tab,
  Tabs,
  Paper,
  Snackbar,
  CircularProgress,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { getMUIXProlicenseKey } from '../../../config';


import { LicenseInfo } from '@mui/x-license';
LicenseInfo.setLicenseKey(getMUIXProlicenseKey());

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  noParkText: {
    textAlign: 'center',
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
      textAlign: 'left',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  media: {
    height: 240,
  },
}));

function ExplorerQuest() {
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const { state, dispatch } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const [parkId, setParkId] = useLocalStorage('parkId', null);
  const [parkLat, setParkLat] = useLocalStorage('parkLat', null);
  const [parkLng, setParkLng] = useLocalStorage('parkLng', null);
  const [parkBoundary, setParkBoundary] = useState([]);

  const [mapCords, setMapCords] = useState({});
  const [allLocations, setLocations] = useState([]);
  const [addZoneButtonDisabled, setAddZoneButtonDisabled] = useState(true);
  const [selectedZone, setSelectedZone] = useState(null);
  const [parkSelected, setParkSelected] = useState(false);
  const [displayMap, setDisplayMap] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  // SNACKBAR
  const [snack, setSnack] = useState({ sev: 'success', msg: '' });
  const [openSnack, setOpenSnack] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [snackMessage, setSnackMessage] = useState('');
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleZoneSelect = zone => {
    if (selectedZone === zone) {
      setSelectedZone(null);
    } else {
      setSelectedZone(zone);
    }
  };

  useEffect(() => {
    setLoading(true);

    if (state.orgId !== null) {
      if (!hasAuthorization(state.userRoles, [1, 2, 3, 4, 12, 13, 14])) {
        navigate('/not-authorized');
      }

      setLoading(false);
    }

    if (parkId) {
      setParkId(parkId);
    }

    setParkSelected(false);
    setDisplayMap(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authContext.tbProps.selectedPark) {
      setParkId(authContext.tbProps.selectedPark.ParkId);
      setParkLat(authContext.tbProps.selectedPark.Latitude);
      setParkLng(authContext.tbProps.selectedPark.Longitude);

      setParkSelected(true);
      setMapCords({
        lat: authContext.tbProps.selectedPark.Latitude,
        lng: authContext.tbProps.selectedPark.Longitude,
      });
      setAddZoneButtonDisabled(false);

      getZones(authContext, authContext.tbProps.selectedPark.ParkId).then(
        data => {
          const zones = data.Hunts.map(z => {
            return new Zone(
              z.HuntId,
              z.ShortName,
              z.LongName,
              z.Description,
              z.Latitude,
              z.Longitude,
              z.Radius,
              z.Color,
              z.ParkId,
              z.IsActive
            );
          });
          setLocations(zones);
          setDisplayMap(true);
          setLoading(false);
        }
      );

      getParkBoundary(authContext, authContext.tbProps.selectedPark.ParkId)
        .then(response => {
          let linePoints = [];
          response.data.BorderPoints.forEach(point => {
            linePoints.push({ lat: point[0], lng: point[1] });
          });
          authContext.tbProps.setParkBoundary(linePoints);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [authContext.tbProps.selectedPark]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {!loading ? (
        <Grid container spacing={2}>
          {!parkSelected ? (
            <Grid item size={12}>
              <Typography variant='h5' className={classes.noParkText}>
                Please Select a Park
              </Typography>
            </Grid>
          ) : (
            <>
              {/* <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <InfoBar />
                </Paper>
              </Grid> */}

              <Grid item size={12}>
                <Paper className={classes.paper}>
                  <Tabs
                    value={tabValue}
                    indicatorColor='primary'
                    textColor='primary'
                    onChange={handleTabChange}
                    variant='fullWidth'
                    aria-label='Explorer Quest tab options'
                  >
                    <Tab label='Explorer Quest' {...A11yProps(0)} />
                    <Tab label='Setup' {...A11yProps(1)} />
                    <Tab label='Rewards Tier' {...A11yProps(2)} />
                  </Tabs>
                </Paper>
              </Grid>

              <TabPanel value={tabValue} index={0}>
                <QuestBuilderTab
                  zones={allLocations}
                  addButtonDisabled={addZoneButtonDisabled}
                  zoneSelected={handleZoneSelect}
                  displayMap={displayMap}
                  cords={mapCords}
                  selected={selectedZone}
                  parkBoundary={parkBoundary}
                />
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <SetupTab parkId={parseInt(parkId)} resetTab={setTabValue} />
              </TabPanel>

              <TabPanel value={tabValue} index={2}>
                <RewardsTierTab
                  parkId={parseInt(parkId)}
                  triggerSnack={setOpenSnack}
                  setMessage={setSnackMessage}
                  setSeverity={setSnackSeverity}
                />
              </TabPanel>
            </>
          )}
        </Grid>
      ) : (
        <CircularProgress />
      )}

      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default React.memo(ExplorerQuest);
