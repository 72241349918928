import axios from 'axios';
import uuid from 'react-uuid';
import uniqolor from 'uniqolor';

import { getBaseUrl, getAPIVersion } from '../config';
import { Point } from '../models/Point';
import Question from '../models/Question';
import QuestionOption from '../models/QuestionOption';

const version = getAPIVersion();
//const path = `/api/${version}/Scavenger/`;
const path = `/api/Points/`;


axios.defaults.headers.post['Content-Type'] = 'application/json';

export function fetchNewPoint() {
  const id = uuid();
  const zoneId = uuid();
  const shortName = '';
  const longName = '';
  const description = '';
  const latitude = 0;
  const longitude = 0;
  const radius = 10;
  const color = uniqolor.random();
  const proximity = 0;
  const imageId = 0;
  const image = '';
  const isHidden = false;
  const isActive = true;
  const Activity = {
    Id: uuid(),
    ActivityType: 1,
    HeaderText: '',
    QuestionText: '',
    Answer: '',
    ResponseWhenCorrect: '',
    ResponseWhenWrong: '',
    Question: {
      Id: uuid(),
      AnswerPosition: 0,
      Options: [],
    },
  };

  return new Point(
    id,
    zoneId,
    shortName,
    longName,
    description,
    latitude,
    longitude,
    radius,
    color.color,
    proximity,
    imageId,
    image,
    isHidden,
    isActive,
    Activity
  );
}

export function fetchNewQuestion() {
  const id = null;
  const answerPosition = null;
  const options = [];

  return new Question(id, answerPosition, options);
}

export function fetchNewQuestionOption(position) {
  const id = uuid();
  const pos = position;
  const optionText = '';
  const responseText = '';
  const isAnswer = false;

  return new QuestionOption(id, pos, optionText, responseText, isAnswer);
}

export async function addPoint(context, data, parkId, zoneId) {
  if (data.Activity.Question && data.Activity.Question.Options && data.Activity.Question.Options.length > 0) {
    for (const option of data.Activity.Question.Options) {
      delete option.Id;
    }
  }
  const response = await fetch(getBaseUrl() + path + 'addpoint', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      parkId: parkId,
      HuntId: zoneId,
      shortName: data.ShortName,
      longName: data.LongName,
      description: data.Description,
      latitude: data.Latitude,
      longitude: data.Longitude,
      radius: data.Radius,
      proximity: data.Proximity,
      image: data.Image,
      color: data.Color,
      isHidden: false,
      activity: {
        activityType: data.Activity.ActivityType,
        headerText: data.Activity.HeaderText,
        questionText: data.Activity.QuestionText,
        answer: data.Activity.Answer,
        responseWhenCorrect: data.Activity.ResponseWhenCorrect,
        responseWhenWrong: data.Activity.ResponseWhenWrong,
        question: data.Activity.Question,
      },
    }),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export async function deletePoint(context, data, parkId) {
  const response = await fetch(getBaseUrl() + path + 'deletepoint', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      parkId: parkId,
      zoneId: data.ZoneId,
      id: data.Id,
    }),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export async function getPoint(context, parkId, zoneId, id) {
  const response = await fetch(getBaseUrl() + path + `getpoint?ParkId=${parkId}&HuntId=${zoneId}&PointId=${id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    /*body: JSON.stringify({
      parkId,
      zoneId,
      id,
    }),*/
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export async function getPoints(context, { ParkId, ZoneId }) {
  const response = await fetch(getBaseUrl() + path + `getpoints?ParkId=${ParkId}&HuntId=${ZoneId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    }
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export function updatePoint(context, data, parkId, zoneId) {
  if (data.Activity.Question && data.Activity.Question.Options && data.Activity.Question.Options.length > 0) {
    for (const option of data.Activity.Question.Options) {
      delete option.Id;
    }
  }

  return axios({
    method: 'post',
    url: getBaseUrl() + path + 'updatepoint',
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      Id: data.PointId,
      parkId: parkId,
      huntId: data.PointId,
      shortName: data.ShortName,
      longName: data.LongName,
      Description: data.Description,
      latitude: data.Latitude,
      longitude: data.Longitude,
      radius: data.Radius,
      proximity: data.Proximity,
      image: data.Image,
      color: data.Color,
      isHidden: false,
      activity: {
        id: data.Activity.Id,
        activityType: data.Activity.ActivityType,
        headerText: data.Activity.HeaderText,
        questionText: data.Activity.QuestionText,
        placeholderText: data.Activity.PlaceholderText,
        answer: data.Activity.Answer,
        responseWhenCorrect: data.Activity.ResponseWhenCorrect,
        responseWhenWrong: data.Activity.ResponseWhenWrong,
        question: data.Activity.Question,
      },
    },
  });
}

export async function updatePointStatus(context, parkId, zoneId, id, isActive) {
  const response = await fetch(getBaseUrl() + path + 'setpointactive', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      parkId,
      zoneId,
      id,
      isActive,
    }),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export async function setPointImage(context, parkId, zoneId, id, image) {
  const response = await fetch(
    getBaseUrl() + `/api/${version}/Images/setscavengerpointimage`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.token,
      },
      body: JSON.stringify({
        parkId: parkId,
        zoneId: zoneId,
        id: id,
        image: image,
      }),
    }
  );

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

//@To do verify endpoint to send data
export function updatepointsequence(context, data, zoneId) {
  const pointSequence = data.map((item, index) => ({
    pointId: item.Id.toString(),
    sequence: index
  }));

  return axios({
    method: 'patch',
    url: getBaseUrl() + '/api/Points/updatepointsequence',
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      huntId: zoneId,
      pointIdSequencePairRequests: pointSequence
    },
  });
}
