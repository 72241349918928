import { Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import { FormInputProps } from "./FormInputProps";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
interface Option {
  label: string;
  id: number;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const FormInputMultipleSelect = ({ name, control, label, errors, options }: FormInputProps) => {
  const [items, setItems] = useState<JSX.Element[]>([]);

  useEffect(() => {
    if (options && options.length) {
      setItems(
        options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        ))
      );
    }
  }, [options]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <InputLabel id={name}>{label}</InputLabel>
          <Select
            labelId={name}
            id={name}
            multiple
            value={value}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              onChange(
                typeof value === "string" ? value.split(",") : value
              );
            }}
            input={<OutlinedInput id={name} label={label} />}
            error={!!error}
            variant="outlined"
            size="small"
            fullWidth
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value:Option) => (
                  <Chip key={value.id} label={value.label} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {options?.map((option) => (
              <MenuItem key={option.id} value={option}>
                <Checkbox checked={value.some((val:Option) => val.id === option.id)} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    />
  );
};
