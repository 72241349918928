import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import imageCompression from 'browser-image-compression';

import AuthContext from 'context/Auth/auth';
import { ImageType } from 'helpers/EnumHelper';
import RewardCard from '../cms/common/RewardCard';

import {
  getScavengerHunt,
  getRewardTiers,
  updateRewardTiers,
} from 'services/Parks.Service';
import {
  getZone,
  getZones
} from 'services/ZonesService';
import { getPoints } from "services/Waypoint.Service";
import { getImage } from 'services/Image.Service';

import { makeStyles } from '@mui/styles'
import {
  Typography,
  Button,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Slider,
} from '@mui/material';
import Grid from '@mui/material/Grid2';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  modal: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    margin: '0 auto',
  },
  formContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px 10px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  media: {
    height: 340,
  },
}));

//TODO
function valuetext(value) {
  return `${value}°C`;
}
function RewardsTierTab({ parkId, triggerSnack, setMessage, setSeverity }) {
  const context = useContext(AuthContext);
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [isDefault, setIsDefault] = useState(false);

  const [currentReward, setCurrentReward] = useState({});
  const [maxNumberOfRewards, setMaxNumberOfRewards] = useState(0);
  const [currentNumberOfRewards, setCurrentNumberOfRewards] = useState(0);
  const [rewardSetting, setRewardSetting] = useState('default');

  // MODAL
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleRewardChange = event => {
    setRewardSetting(event.target.value);
  };

  const handleFormUpdate = ({ name, value }) => {
    setCurrentReward({ ...currentReward, [name]: value });
  };

  async function handleBadgeImageUpload(event, rewardTier) {
    const imageFile = event.target.files[0];
    console.log(imageFile);

    const options = {
      maxSize: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);

      await imageCompression
        .getDataUrlFromFile(compressedFile)
        .then((resolve, reject) => {
          if (!!!reject) {
            setLoading(true);
            rewardTier.Image = resolve;
            setRewards(rewards);
            setLoading(false);
            /*setImage(parkId, resolve)
              .then((response) => {
                setMainImage(resolve);
                setOpenSnack(true);
                setLoading(false);
              })
              .catch((error) => {
                console.error(error);
                setOpenSnack(true);
                setLoading(false);
              });*/
          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  const handleBadgeEdit = (event, reward) => {
    event.preventDefault();

    console.log(reward);
    setCurrentReward(reward);
    setOpen(true);
  };

  const confirmMenuClose = () => {
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setOpen(false);
  };

  const handleRewardsSave = () => {
    setLoading(true);

    let rewardsCopy = rewards;
    const updatedReward = rewardsCopy.find(r => r.Id === currentReward.Id);
    const indexForReward = rewardsCopy.indexOf(updatedReward);

    rewardsCopy.splice(indexForReward, 1, currentReward);

    // This will create the rewards for the given parkId.
    if (isDefault) {
      rewardsCopy.forEach(reward => {
        delete reward.Id;
      });
    }

    rewardsCopy.forEach(reward => {
      delete reward.ParkId;
      // delete reward.BadgeImageId;
      reward.RequiredChallengesPct = parseInt(reward.RequiredChallengesPct);
      reward.RequiredPointsPct = parseInt(reward.RequiredPointsPct);
      reward.TierPriority = parseInt(reward.TierPriority);
    });

    updateRewardTiers(context, parkId, rewardsCopy)
      .then(response => {
        console.log(response.data.Message);

        setIsDefault(false);
        setRewards(rewardsCopy);
        setCurrentReward({});
        setMessage(response.data.Message);
        setSeverity('success');
        triggerSnack(true);
      })
      .catch(error => {
        console.error(error);

        setMessage('Error Occurred');
        setSeverity('error');
        triggerSnack(true);
      });

    setOpen(false);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    // Get Max Number of Reward Possible
    getZones(context, parkId).then(response => {
      const activeGameZones = response.Hunts;
      
      const activeGamePoints = [];

      activeGameZones.forEach(agz => {
        getPoints(context, { ParkId: parkId, ZoneId: agz.HuntId } , )
        .then(response => {
          activeGamePoints.push(response.Points.length);
          setMaxNumberOfRewards(activeGamePoints.reduce(reducer))
        });
        
      });

      //setMaxNumberOfRewards(activeGamePoints.reduce(reducer));
    });

    getRewardTiers(context, parkId)
      .then(response => {
        console.log(response.data);
        const rewards = response.data;

        rewards.forEach(reward => {
          if (reward.ParkId === -1) {
            reward.ParkId = parkId;
            setIsDefault(true);
          }

          if (reward.BadgeImageId !== null) {
            getImage(context, reward.BadgeImageId, ImageType.Badge).then(
              response => {
                console.log(response);
              }
            );
          } else {
            reward.Image = null;
          }
        });

        setRewards(response.data);
        setCurrentNumberOfRewards(response.data.length);

        if (response.data.length > 3) {
          setRewardSetting('per-point');
        }

        setLoading(false);
      })
      .catch(error => {
        console.error(error.toJSON());
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading ? (
        <Grid container justify='center' alignItems='center'>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={1}>
          <Grid item size={12}>
            <h1>Explorer Quest Rewards</h1>

            <Grid
              container
              spacing={2}
              justify='space-between'
              alignItems='center'
            >
              <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={
                    currentNumberOfRewards >= maxNumberOfRewards ||
                    rewardSetting === 'default'
                  }
                  onClick={handleOpen}
                >
                  Add Reward
                </Button>
              </Grid>

              <Grid item>
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>Reward Setting</FormLabel>
                  <RadioGroup
                    row
                    aria-label='reward-style'
                    name='rewards-options'
                    value={rewardSetting}
                    onChange={handleRewardChange}
                  >
                    <FormControlLabel
                      value='default'
                      control={<Radio />}
                      label='Default'
                    />
                    <FormControlLabel
                      value='per-point'
                      control={<Radio />}
                      label='Per Point'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {rewards.map(reward => {
            return (
              <Grid item xs={12} sm={4} key={reward.Id}>
                <RewardCard
                  imgSrc={''}
                  tierName={reward.TierName}
                  completionMessage={reward.CompletionMessage}
                  nextStepsMessage={reward.NextStepsMessage}
                  onBadgeImageUpload={handleBadgeImageUpload}
                  onBadgeEdit={handleBadgeEdit}
                  rewardTier={reward}
                />
              </Grid>
            );
          })}
        </Grid>
      )}

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Reward Tier</DialogTitle>
        <DialogContent>
          <form className={classes.formContainer}>
            <FormControl className={classes.formControl}>
              <TextField
                id='reward-tier-name'
                label='Tier Name'
                name='TierName'
                variant='outlined'
                placeholder='Tier Name'
                value={currentReward.TierName}
                fullWidth
                onChange={e => handleFormUpdate(e.target)}
              />
            </FormControl>

            <FormControl className={classes.formControl} disabled={false}>
              <TextField
                id='reward-tier-priority'
                label='Tier Priority'
                name='TierPriority'
                variant='outlined'
                placeholder='Tier Priority'
                value={currentReward.TierPriority}
                fullWidth
                onChange={e => handleFormUpdate(e.target)}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <TextField
                id='outlined-basic'
                label='Completion Message'
                variant='outlined'
                placeholder='Completion Message'
                value={currentReward.CompletionMessage}
                multiline
                rowsMax={4}
                fullWidth
                name='CompletionMessage'
                onChange={e => handleFormUpdate(e.target)}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <TextField
                id='outlined-basic'
                label='Next Steps Message'
                variant='outlined'
                placeholder='Next Steps Message'
                value={currentReward.NextStepsMessage}
                multiline
                rowsMax={4}
                fullWidth
                name='NextStepsMessage'
                onChange={e => handleFormUpdate(e.target)}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <TextField
                id='outlined-basic'
                label='Required Challenges Percentage'
                variant='outlined'
                placeholder='Required Challenges Percentage'
                value={currentReward.RequiredChallengesPct}
                fullWidth
                name='RequiredChallengesPct'
                onChange={e => handleFormUpdate(e.target)}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <TextField
                id='outlined-basic'
                label='Required Points Percentage'
                variant='outlined'
                placeholder='Required Points Percentage'
                value={currentReward.RequiredPointsPct}
                fullWidth
                name='RequiredPointsPct'
                onChange={e => handleFormUpdate(e.target)}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMenuClose} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={handleRewardsSave}
            variant='contained'
            color='primary'
          >
            Save Reward
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

RewardsTierTab.propTypes = {
  parkId: PropTypes.number.isRequired,
  triggerSnack: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  setSeverity: PropTypes.func.isRequired,
};

export default RewardsTierTab;
