import { useState, useEffect, useContext } from 'react';
import { navigate } from 'hookrouter';

import { UserContext } from 'context/UserContext';
import { hasAuthorization } from 'helpers/HasAuthorization';
import Alert from 'components/common/Alert';
import DataTools from './data/DataTools';
import SpoofingTools from './spoofing/SpoofingTools';
import MessageTools from './messages/MessageTools';
import LogTools from './logs/LogTools';
import Elastic from './logs/Elastic';

import { makeStyles } from '@mui/styles'
import { Snackbar } from '@mui/material';
import Grid from '@mui/material/Grid2';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#edeff2',
  },
}));

function DevTools() {
  const { state, dispatch } = useContext(UserContext);
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  // SNACKBAR
  const [openSnack, setOpenSnack] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [snackMessage, setSnackMessage] = useState('');
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  // Check for Authorization
  useEffect(() => {
    setLoading(true);
    if (state.orgId !== null) {
      if (!hasAuthorization(state.userRoles, [1])) {
        navigate('/not-authorized');
      } else {
        setLoading(false);
      }
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loading ? null : (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DataTools
                triggerSnack={setOpenSnack}
                setMessage={setSnackMessage}
                setSeverity={setSnackSeverity}
              />
            </Grid>
            {/*<Grid item xs={12}>
              <SpoofingTools
                triggerSnack={setOpenSnack}
                setMessage={setSnackMessage}
                setSeverity={setSnackSeverity}
              />
            </Grid>
            <Grid item xs={12}>
              <MessageTools
                triggerSnack={setOpenSnack}
                setMessage={setSnackMessage}
                setSeverity={setSnackSeverity}
              />
            </Grid>
            <Grid item xs={12}>
              <LogTools
                triggerSnack={setOpenSnack}
                setMessage={setSnackMessage}
                setSeverity={setSnackSeverity}
              />
            </Grid>
            <Grid item xs={12}>
              <Elastic />
            </Grid> */}
          </Grid>
        </div>
      )}

      {/* TODO */}
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default DevTools;
