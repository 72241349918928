//#region [rgba(0, 205, 30, 0.1)] Map
export function setMapOptions(currentZone, parkLat, parkLng) {
  let options = null;

  // Latitude of 0 is default (empty) location.
  if (currentZone && currentZone.Latitude !== 0) {
    options = {
      center: { lat: currentZone.Latitude, lng: currentZone.Longitude },
      zoom: 18,
      mapTypeId: 'satellite',
    };

    return options;
  }

  // options = {
  //   center: { lat: parkLat, lng: parkLng },
  //   zoom: 18,
  //   disableDefaultUI: true,
  //   mapTypeControl: true,
  //   mapTypeControlOptions: {
  //     style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
  //     mapTypeIds: ['roadmap', 'terrain'],
  //   },
  // };
  options = {
    center: { lat: parkLat, lng: parkLng },
    zoom: 18,
  };

  return options;
}
//#endregion

//#region [rgba(241, 196, 15, 0.1)] Markers
export function createMarker(map, latitude, longitude, title = '') {
  return new window.google.maps.Marker({
    map,
    position: { lat: latitude, lng: longitude },
    title: title,
  });
}

export function createCustomMarker(map, latitude, longitude, title = '', id) {
  return new window.google.maps.Marker({
    map,
    position: { lat: latitude, lng: longitude },
    title: title,
    ZoneId: id,
  });
}

export function getNewMarkers(markers, markerList = []) {
  let result = [];

  markers.forEach(marker => {
    const found = markerList.find(m => m.i === marker.Id);
    if (!found) {
      result.push(marker);
    }
  });

  return result;
}

export function createExistingZoneMarkers(map, zones, currentZoneId = null) {
  const zoneMarkers = [];
  const zoneCircles = [];

  // Remove current location during edit.
  if (currentZoneId) {
    zones = zones.filter(zone => zone.ZoneId !== currentZoneId);
  }

  zones.forEach(zone => {
    const markerOptions = {
      map: map,
      position: {
        lat: zone.Latitude,
        lng: zone.Longitude,
      },
    };

    const marker = createCustomMarker(
      map,
      zone.Latitude,
      zone.Longitude,
      zone.ShortName,
      zone.ZoneId
    );

    marker.setIcon({
      path: window.google.maps.SymbolPath.CIRCLE,
      scale: 5,
      strokeWeight: 3,
      strokeColor: zone.Color,
    });

    const circleOptions = {
      strokeColor: zone.Color,
      strokeOpacity: 1,
      strokeWeight: 3,
      map: map,
      center: markerOptions.position,
      radius: zone.Radius,
      fillColor: zone.Color,
      fillOpacity: 0.55,
    };

    const circle = new window.google.maps.Circle(circleOptions);
    circle.bindTo('center', marker, 'position');

    zoneCircles.push(circle);
    zoneMarkers.push(marker);
  });

  return [zoneMarkers, zoneCircles];
}

export function createExistingPointMarkers(map, points, currentPointId = null) {
  const markers = [];
  const circles = [];
  // Remove current location during edit.
  if (currentPointId) {
    points = points.filter(point => point.Id !== currentPointId);
  }

  points.forEach(point => {
    const markerOptions = {
      map: map,
      position: {
        lat: point.Latitude,
        lng: point.Longitude,
      },
    };

    const marker = createMarker(
      map,
      point.Latitude,
      point.Longitude,
      point.ShortName
    );

    marker.setIcon({
      path: window.google.maps.SymbolPath.CIRCLE,
      scale: 5,
      strokeWeight: 3,
      strokeColor: point.Color,
    });

    const circleOptions = {
      strokeColor: point.Color,
      strokeOpacity: 1,
      strokeWeight: 3,
      map: map,
      center: markerOptions.position,
      radius: point.Radius,
      fillColor: point.Color,
      fillOpacity: 0.75,
    };

    const circle = new window.google.maps.Circle(circleOptions);
    circle.bindTo('center', marker, 'position');

    circles.push(circle);
    markers.push(marker);
  });

  return [markers, circles];
}

export function createParentZoneForScavengerPoints(map, zone) {
  const markerOptions = {
    map: map,
    position: {
      lat: zone.Latitude,
      lng: zone.Longitude,
    },
  };
  const marker = createMarker(
    map,
    zone.Latitude,
    zone.Longitude,
    zone.ShortName
  );
  marker.setIcon({
    path: window.google.maps.SymbolPath.CIRCLE,
    scale: 5,
    strokeWeight: 3,
    strokeColor: zone.Color,
  });

  const circleOptions = {
    strokeColor: zone.Color,
    strokeOpacity: 1,
    strokeWeight: 5,
    map: map,
    center: markerOptions.position,
    radius: zone.Radius,
    editable: false,
    fillColor: '#f8f8f8',
    fillOpacity: 0.55,
  };

  const circle = new window.google.maps.Circle(circleOptions);
  circle.bindTo('center', marker, 'position');

  return circle;
}

export function clearLastMarker(lastMarker) {
  if (lastMarker.marker) {
    lastMarker.marker.setMap(null);
    lastMarker.marker = null;
  }

  if (lastMarker.circle) {
    lastMarker.circle.setMap(null);
    lastMarker.circle = null;
  }

  if (lastMarker.prox) {
    lastMarker.prox.setMap(null);
    lastMarker.prox = null;
  }
}

export function isLocationInExistingZone(lat, lng, zoneMarkers) {
  let found = false;
  zoneMarkers.forEach(zMarker => {
    if (zMarker.getBounds().contains({ lat, lng })) found = true;
  });

  return found;
}
//#endregion

//#region [rgba(52,152,219,0.1)] Shapes
export function createCircle(map, radius, fillColor) {
  return new window.google.maps.Circle({
    map,
    radius,
    fillColor,
  });
}
//#endregion

//#region [rgba(231,76,60,0.1)] Map Rules
export function locationBoundsChecker(
  currentLocation,
  currentLocationRadius,
  allLocations
) {
  /*
  get center points of both locations
  calc distance from parent point and add radius
  */
  // console.log(parentLocation.getRadius());
  // return parentLocation.getBounds().intersects(currentLocationBounds);
  // console.log('currentLocation:: ', currentLocationBounds);
  // console.log('parentLocation:: ', parentLocation.getBounds());
}

export function collisionBoundsChecker(currentLocation, allLocations) {
  /*
  get center points of locations and calc distance
  check if radius's added are more than distance
  */
}
//#endregion
